<template>
  <a-modal
    title="编辑"
    width="50%"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="分区名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input
                placeholder="请输入"
                v-decorator="['name', { rules: [{ required: true, message: '分区名称！' }] }]"
              />
            </a-form-item>
            <!-- 排序 -->
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-input-number
                placeholder="请输入"
                :min="1"
                :precision="0"
                :max="10000000"
                style="width: 40%; min-width: 100px"
                v-decorator="['sort', { rules: [{ required: true, message: '请输入排序！' }], initialValue: 10 }]"
              />
            </a-form-item>
            <a-form-item label="主题色" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <sketch-picker v-model="color" />
            </a-form-item>
            <a-form-item label="黑名单" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select mode="multiple" v-decorator="['blacklistAreaIds']" allow-clear placeholder="请选择">
                <a-select-option v-for="(item, index) in areas" :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="是否启用分类筛选" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-radio-group
                v-decorator="[
                  'isCategorySearch',
                  {
                    rules: [{ required: true, message: '请选择是否启用分类筛选' }],
                  },
                ]"
              >
                <a-radio :value="1">是</a-radio>
                <a-radio :value="2">否</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="秒杀专区" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-radio-group
                v-decorator="[
                  'isFlashSale',
                  { initialValue: 2, rules: [{ required: true, message: '请选择秒杀专区' }] },
                ]"
                @change="handleIsFlashSaleChange"
              >
                <a-radio :value="1">否</a-radio>
                <a-radio :value="2">是</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item v-if="isFlashSale === 2" label="秒杀开始时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-time-picker
                v-decorator="['flashSaleBegin', { rules: [{ required: true, message: '请选择秒杀开始时间' }] }]"
                format="HH:mm"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { Sketch } from 'vue-color'
import { partitionEdit } from '@/api/modular/mallLiving/partition'
import moment from 'moment'
export default {
  props: {
    areas: {
      type: Array,
      required: true,
    },
  },
  components: {
    'sketch-picker': Sketch,
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      id: '',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      //秒杀专区 1否 2是
      isFlashSale: 2,
      color: { hex: '#194d33' },
    }
  },

  methods: {
    // 初始化方法
    edit(record) {
      const { id, color, name, sort, isFlashSale, flashSaleBegin, blacklistAreaIds = '', isCategorySearch } = record
      this.id = id
      this.visible = true
      this.isFlashSale = +isFlashSale
      this.color = {
        hex: color,
      }
      this.$nextTick(() => {
        this.form.setFieldsValue({
          name,
          sort,
          isCategorySearch,
          blacklistAreaIds: blacklistAreaIds ? blacklistAreaIds.split(',') : [],
          isFlashSale: +isFlashSale,
        })

        if (this.isFlashSale === 2) {
          setTimeout(() => {
            this.form.setFieldsValue({
              flashSaleBegin: flashSaleBegin ? moment(flashSaleBegin, 'HH:mm') : '',
            })
          }, 100)
        }
      })
    },
    handleIsFlashSaleChange(event) {
      console.log(event)
      this.isFlashSale = event.target.value
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          partitionEdit({
            ...values,
            flashSaleBegin: values.flashSaleBegin ? values.flashSaleBegin.format('HH:mm') : '',
            color: this.color.hex,
            blacklistAreaIds: values.blacklistAreaIds ? values.blacklistAreaIds.join(',') : '',
            id: this.id,
          }).then((res) => {
            if (res.success) {
              this.$message.success('编辑成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
